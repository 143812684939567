import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

function Cancel() {
    const { orderNumber } = useParams();

    const paymentCancel = require("./asset/images/payment-cancelled.png");

    const textStyle = {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#ffffff',
        textAlign: 'center',
        marginBottom: 10
    };

    const buttonStyle = {
        marginTop: 10,
        width: '240px',
        padding: 10,
        borderStyle: 'none',
        borderRadius: 5,
        backgroundColor: '#333333',
        fontSize: 18,
        fontWeight: 'bold',
        color: '#ffffff',
        cursor: 'pointer'
    };

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 20
    }}>
            <div style={{
                textAlign: 'center',
                marginBottom: 20
            }}>
                <img src={paymentCancel} styel={{ width: 96 }} />
            </div>
            <div style={textStyle} >ยกเลิกการจองโต๊ะ</div>
            <div style={textStyle} >เลขการจอง: {orderNumber}</div>
            <button style={buttonStyle}
                onClick={()=>{
                    document.location = "https://www.pafekutogroup.com/reserve/";
                }}
            >กลับหน้าจอง</button>
        </div>
    

}

export default Cancel;