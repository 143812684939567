import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Reservation from './Reservation';
import Result from './Result';
import Fail from './Fail';
import Cancel from './Cancel';
import Reserved from './Reserved';
import Login from './Login';
import Transactions from './admin/Transactions';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/reserve" element={<Reservation />} />
          <Route path="/reserve/result/:orderNumber" element={<Result />} />
          <Route path="/reserve/fail/:orderNumber" element={<Fail />} />
          <Route path="/reserve/cancel/:orderNumber" element={<Cancel />} />
          <Route path="/reserve/reserved/:orderNumber" element={<Reserved />} />
          <Route path="/reserve/login" element={<Login />} />
          <Route path="/reserve/admin/transactions" element={<Transactions />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
